<template>
    <!-- <v-container fluid fill-height> -->
        <!-- <v-row> -->
            <!-- <v-col cols="12"> -->
                <v-app-bar color="primary" absolute elevate-on-scroll dark>
                    <v-toolbar-title>{{ $t("appName") }}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn icon v-b-modal.modal-qr-reader>
                        <v-icon>mdi-qrcode</v-icon>
                    </v-btn>
                    
                    <language-select></language-select>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-account</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                v-for="(menu, index) in menuList"
                                :key="index"
                                @click="menu.action"
                            >
                                <v-list-item-title>{{
                                    menu.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <b-modal id="modal-qr-reader" :title="$t('qrDialog.title')">
                        <qrcode-stream @decode="onDecode"></qrcode-stream>
                    </b-modal>
                </v-app-bar>
                <!-- <slot /> -->
            <!-- </v-col> -->
        <!-- </v-row> -->
    <!-- </v-container> -->
</template>
<script>
import { mapActions } from "vuex";
import LanguageSelect from "../components/LanguageSelect.vue";
import { QrcodeStream } from 'vue-qrcode-reader'
import CustomerAPI from '@/api/customer';

export default {
    name: "MainLayout",
    computed: {
        menuList(){
            return [
                {
                    name: this.$t("account.title"),
                    action: () => {
                        this.$router.push("/edit-profile");
                    },
                },
                {
                    name: this.$t("changePassword.title"),
                    action: () => {
                        this.$router.push("/change-password");
                    },
                },
                {
                    name: this.$t("membership.title"),
                    action: () => {
                        this.$router.push("/membership");
                    },
                },
                {
                    name: this.$t("promotion.yourPromotion"),
                    action: () => {
                        this.$router.push("/promotion");
                    },
                },
                {
                    name: this.$t("promotion.redeemPromotion"),
                    action: () => {
                        this.$router.push("/redeem-promotion");
                    },
                },
                {
                    name: this.$t("home.logout"),
                    action: () => {
                        this.logOut();
                        this.$router.push("/login");
                    },
                },
            ];
        }
    },
    components: {
        LanguageSelect,
        QrcodeStream
    },
    methods: {
        ...mapActions({
            logOut: "auth/customerLogout",
        }),
        onDecode (code) {
            CustomerAPI.scanEventQRCode({code: code}).then(({data}) => {
                if (data.valid) {
                    this.$bvModal.msgBoxOk(this.$t("promotion.message-promotionAdded"), this.messageBoxOk);
                }

                if (!data.valid) {
                    this.$bvModal.msgBoxOk(data.reason, this.messageBoxOk);
                }
                
                this.$bvModal.hide("modal-qr-reader");
            })
            .catch(err => {
                this.$bvModal.msgBoxOk(err?.response?.code, this.messageBoxOk);
                this.$bvModal.hide("modal-qr-reader");
            })
        }
    },
};
</script>